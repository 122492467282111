import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SobreNos from "../components/sobre-nos"
import BackgroundImage from "gatsby-background-image"
import { graphql, Link } from "gatsby"

const Sobre = ({ data }) => {
  return (
    <Layout showHero={true}>
      <SEO title="História da Cedilar Porto Alegre | Reparo de Eletrodomésticos" />
      <SobreNos />
      <div className="flex container mx-auto justify-between px-4 md:px-0">
        <div>
          <h1 className="pb-2 pt-12 text-xl font-bold">Cedilar Porto Alegre</h1>
          <p className="pb-6">
            Com as grandes mudanças de temperatura e estações que atingem de
            forma direta o morador de Porto Alegre, fica bem difícil não passar
            por momentos onde algum equipamento da sua casa não sofra as
            consequências, acabando por precisar de bons reparos urgentes. Mas,
            você tem em quem confiar de verdade para te ajudar?
          </p>
          <p className="pb-6">
            A CeDilar vem com esse objetivo, ajudar você a ter o melhor em
            assistência técnica, seja no seu chuveiro ou torneira que precise de
            água quente para amenizar o frio, ou ainda na limpeza e manutenção
            do seu eletrodoméstico, a nossa intenção sempre será ofertar
            excelência em nossos serviços.
          </p>
          <p className="pb-6">
            A{" "}
            <Link to="/" aria-label="Ir a Página Inicial">
              Assistência Técnica CeDilar
            </Link>{" "}
            trabalha há 30 anos no mercado oferecendo o que há de melhor em seus
            serviços, com uma equipe preparada, especializa e experiente. Quer
            saber tudo sobre nós e como podemos te ajudar? Então, vamos
            conhecer!
          </p>
          <h2 className="pb-2 text-xl font-bold">Nossa História</h2>
          <p className="pb-6">
            A nossa história se inicia há muitos anos atrás, começando com a
            nossa inauguração no dia 15 de maio de 1990, onde desde esta data
            trabalhamos com serviços de assistência especializada, e a cada ano
            que se passa procuramos aprender mais para sempre oferecer o que há
            de mais atual e excelente aos nossos clientes.
          </p>
          <p className="pb-6">
            Com esses 30 anos de negócios pudemos crescer, ampliar nossas
            fronteiras e aprender mais sobre como trazer a melhor qualidade em
            assistência técnica especializada, sendo hoje autorizados pelas
            maiores marcas de eletrodomésticos e equipamentos do mercado,
            aumentando a segurança e qualidade dos nossos processos.
          </p>
          <p className="pb-12">
            Em todos esses anos fomos geridos pela mesma dona, e ainda pelo
            mesmo técnico, ambos com enorme experiência no mercado, além de
            qualificação e total qualidade de serviços. A equipe soma os
            melhores conhecimento e alia isso a experiência de nossa gestão,
            trabalhando de forma a atingir o máximo de qualidade em nosso
            atendimento.
          </p>
        </div>
      </div>
      <BackgroundImage
        Tag="section"
        fluid={data?.parqueFarroupilha?.childImageSharp?.fluid}
        className="bg-scroll h-64"
        alt="Parque Farroupilha Porto Alegre"
      />
      <div className="flex container mx-auto justify-between px-4 md:px-0">
        <div>
          <h2 className="pb-2 pt-12 text-xl font-bold">
            Serviços Dentro E Fora da Garantia
          </h2>
          <p className="pb-6">
            Como já dito, nosso trabalho é autorizado pelas melhores marcas de
            eletrodomésticos e equipamentos do mercado, sendo assim, você pode
            ter a segurança de, ao mandar seus produtos para nossa assistência,
            ter a manutenção da garantia de fábrica e ainda de reposição de
            peças originais, trabalhando com o que há de melhor.
          </p>
          <p className="pb-6">
            As marcas que atuam de forma parceira em questão de autorização para
            desenvolvimento de atividades de assistência são Mondial, Cardal,
            Sintex, Lorenzetti, Hydra Corona e ainda Philips Walita. Todas
            confiam em nosso trabalho, devido anos de experiência, e garantem a
            máxima qualidade no serviço ofertado pela nossa empresa.
          </p>
          <h2 className="pb-2 text-xl font-bold">
            Assistência técnica de qualidade
          </h2>
          <p className="pb-6">
            Com a Assistência Técnica CeDilar você conta com uma assistência
            técnica de qualidade, com opção de atendimento a domicílio, de forma
            rápida e eficaz, tendo todos os equipamentos necessários para o seu
            conserto o mais rápido possível à sua residência.
          </p>
          <p className="pb-6">
            Com experiência profissional e 30 anos no mercado, buscamos o melhor
            a atender nossos clientes e fazê-los sentirem seguros, dando
            garantia de 3 meses em nossas peças de reposição, já que todas são
            originais de fábrica pela assistência técnica autorizada das
            principais marcas do mercado.
          </p>
          <p className="pb-12">
            Para nos chamar basta contactar pelo nosso telefone (51) 3217-9658,
            ou ainda se dirigir até nosso ponto fixo de atendimento presencial,
            na Avenida Ipiranga, 1477, Azenha, Porto Alegre, RS. Estaremos
            esperando por você para oferecer o que há de melhor em assistência
            técnica especializa CeDilar!
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    parqueFarroupilha: file(
      relativePath: { eq: "Parque-Farroupilha-Porto-Alegre.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default Sobre
