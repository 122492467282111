import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPaperPlane,
  faTrophy,
  faToolbox,
  faAward,
} from "@fortawesome/free-solid-svg-icons"

const SobreNos = () => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 text-center bg-gray-200 px-4 py-8">
        <div>
          <FontAwesomeIcon
            icon={faPaperPlane}
            className="text-3xl bg-blue-600 text-white rounded-md p-1"
          />
          <h3 className="text-xl font-bold underline">Atendimento Rápido</h3>
          <p>
            Temos todos equipamentos necessários para seu consert o mais rápido
            possível.
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faTrophy}
            className="text-3xl bg-blue-600 text-white rounded-md p-1"
          />
          <h3 className="text-xl font-bold underline">30 Anos De Mercado</h3>
          <p>
            Abrimos nossas portas no dia 15 de Maio de 1990. Com mais de 30 anos
            servindo consumidores da grande região de Porto Alegre.
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faToolbox}
            className="text-3xl bg-blue-600 text-white rounded-md p-1"
          />
          <h3 className="text-xl font-bold underline">
            Experiência Profissional
          </h3>
          <p>
            Nossos técnicos são treinados pelas melhores marcas para prestar
            serviços de alta qualidade.
          </p>
        </div>
        <div>
          <FontAwesomeIcon
            icon={faAward}
            className="text-3xl bg-blue-600 text-white rounded-md p-1"
          />
          <h3 className="text-xl font-bold underline">Garantia de 3 Meses</h3>
          <p>
            Sempre usamos peças originais de fábrica, assim podemos oferecer um
            alto prazo de garantia ao seu investimento.
          </p>
        </div>
      </div>
    </>
  )
}

export default SobreNos
